import React from 'react'
import { Link as GLink } from 'gatsby'
import { Flex, Box, Text, Heading, Card, Badge, Link } from 'theme-ui'
import MemphisPattern from '@components/MemphisPattern'
import Avatar from '@components/Avatar'
import Navigation from '@components/Navigation'
import attachSocialIcons from '@helpers/attachSocialIcons'

import './index.css'

const styles = {
  card: {
    position: `relative`
  },
  wrapper: {
    flexDirection: [`column`],
    position: `relative`,
    zIndex: 3
  },
  avatarColumn: {
    flexBasis: `1/3`
  },
  infoColumn: {
    flexBasis: `2/3`
  },
  innerBox: {
    flexBasis: `1/2`,
    flexGrow: 1,
    px: [0, 3],
    mt: [3, 0]
  },
  subheader: {
    color: `omegaDark`
  },
  name: {
    textAlign: [`center`, `left`],
    mt: [3, 0],
    px: 3
  },
  bio: {
    textAlign: [`justify`]
  },
  socialList: {
    a: {
      m: 0
    }
  },
  link: {
    position: `absolute`,
    top: 10,
    right: 10,
    zIndex: 3
  },
  pattern: {
    borderRadius: `lg`
  },
  gradient: {
    size: `full`,
    borderRadius: `lg`,
    position: `absolute`,
    left: 0,
    top: 0,
    zIndex: 2,
    background: [
      t =>
        `linear-gradient(0deg, ${t.colors.contentBg} 20%, rgba(255, 255, 255, 0) 80%)`,
      t =>
        `linear-gradient(270deg, ${t.colors.contentBg} 20%, rgba(255, 255, 255, 0) 100%)`
    ]
  }
}

const Subheader = ({ children }) => (
  <Heading variant='h4' sx={styles.subheader}>
    {children}
  </Heading>
)

const AuthorAvatar = ({ name, thumbnail, slug }) =>
  thumbnail ? (
    <Box>
        <Avatar avatar={thumbnail} alt={name} />
    </Box>
  ) : null

const AuthorName = ({ name, slug }) => (
  <Box sx={styles.name}>
    <Heading variant='h3'>
      <Link as={GLink} to={slug}>
        {name}
      </Link>
    </Heading>
  </Box>
)

const AuthorBio = () => (
  <>

    <Box>
      <iframe className='videoEmbed' src="https://www.youtube.com/embed/BscRG7ETVzU"></iframe>
    </Box>

    <Box sx={styles.bio}>
      <p style={{ fontStyle: 'italic' }}>Place au professionnalisme, à l’efficacité, à la transparence mais surtout à la considération humaine.</p>
      <p>Et si je vous dis que la recette pour réussir votre projet immobilier est l’authenticité ?</p>
      <p>En concevant Chloé Carmona Immobilier, j’ai souhaité créer une agence immobilière qui soit tout sauf une simple agence immobilière …
  Étrange me direz-vous ?! Mais en même temps, pourquoi avoir créé une agence, si c’est pour faire la même chose que tout le monde ?</p>

      <p>Non… Je rêvais d’une agence où je pourrais vous aider autrement, où je pourrais vous parler avec une totale sincérité et moins de codes, comme si ma priorité ne serait plus machinalement de rentrer un mandat de vente pour avoir du stock, mais d’analyser votre situation, de vous conseiller en toute objectivité pour réduire votre marge d’erreur. Finalement, de vous apporter une vraie valeur ajoutée personnalisée !</p>

      <p>D’accord mais comment ? Je sens que vous êtes curieux…🤓</p>

      <p>Pour commencer en vous considérant, en collaborant avec vous comme si ensemble nous formions une équipe, en vous proposant un service de qualité sur mesure, semblable à un délicieux gâteau dont la recette a été mise au point grâce à de multiples ingrédients que j’ai goûté dans différents grands réseaux immobiliers depuis 2012. Ma recette vous permettra de balayer vos difficultés et d’atteindre vos objectifs.</p>

    <p>Vous dites ? Ah ! 😄 Quelle est ma recette ?! Et bien… Je vous laisse me contacter pour en parler plus en détail et voir comment l’utiliser dans votre situation ! </p>

    <p>Pour ceux qui voudraient me connaître davantage, je suis Chloé CARMONA, agent immobilier sur la métropole de Bordeaux et sur le Bassin d'Arcachon. 
  Je travaille dans l’immobilier depuis 2012, j’ai réalisé de belles performances et j’ai été sollicitée à des postes à responsabilité dans une enseigne nationale et ensuite, internationale. </p>

    <p>Ma passion pour l’art, le design, l’architecture, résonne en moi lorsque je pousse la porte de vos biens. Les matières pointues telles que le droit de l’immobilier, la technique du bâtiment, l’architecture étudiées lors de mon BTS Professions Immobilières, ainsi que mes années d'expérience m’apportent aujourd’hui une maîtrise que je suis ravie de mettre à votre disposition et qui me démarque de mes confrères conventionnels. </p>

    <p>Mon objectif de fond est de vous aider à réaliser en toute sérénité vos projets immobiliers, qui font souvent partie des moments les plus importants de votre vie.</p>

    <p>Je me réjouis d’avance de vous accompagner dans la réalisation de vos projets ! </p>

    <p>A bientôt ☺️</p>

    <p>Chloé</p>

  </Box>
  </>

)

const AuthorSkills = ({ skills }) =>
  skills ? (
    <Box sx={styles.innerBox}>
      <Subheader>Expertise</Subheader>
      {skills.map(skill => (
        <Text key={`skill-${skill}`}>{skill}</Text>
      ))}
    </Box>
  ) : null

const AuthorSocialMedia = ({ social }) =>
  social ? (
    <Box sx={styles.innerBox}>
      <Subheader>Social Media</Subheader>
      <Navigation
        variant='vertical'
        items={attachSocialIcons(social)}
        wrapperStyle={styles.socialList}
      />
    </Box>
  ) : null

const AuthorExpanded = ({ author, withLink }) => {
  if (!author) return null

  const { skills, social } = author

  return (
    <Card variant='paper' sx={styles.card}>
      <Flex sx={styles.wrapper}>
        
        <Box>
          {/* <AuthorName {...author} /> */}
          <Flex sx={styles.wrapper}>
            <Box sx={styles.innerBox}>
              <AuthorBio {...author} />
            </Box>
            {/* {(social || skills) && (
              <Box sx={styles.innerBox}>
                <Flex>
                  <AuthorSkills {...author} />
                  <AuthorSocialMedia {...author} />
                </Flex>
              </Box>
            )} */}
          </Flex>
        </Box>
      <Box sx={styles.avatarColumn}>
        <AuthorAvatar {...author} />
      </Box>
      </Flex>
      {/* {withLink && (
        <Badge variant='tag' as={GLink} to={author.slug} sx={styles.link}>
          Voir posts
        </Badge>
      )}
      <Box sx={styles.gradient} /> */}
      <MemphisPattern sx={styles.pattern} />
    </Card>
  )
}

export default AuthorExpanded
